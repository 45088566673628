/**
 * This is the index file for the page where users can opt out of us selling their PII
 * business.com/optout
 */
import React from 'react'
import styled from '@emotion/styled'

// Import Components
import CcpaForm from 'components/CcpaForm'
import { Layout, SimpleHeader } from '@jelly/components'

const META = {
	canonical: `${process.env.GATSBY_SITE_HOST}/optout/`,
	description:
		'You have the right to opt out of the sale of your personal information by business.com, buyerzone.com and businessnewsdaily.com.',
	robots: 'noindex, nofollow',
	title: 'Right to Opt Out - California Residents - business.com',
}

const HEADER_PROPS = {
	breadcrumbs: { primary: { name: 'OptOut', slug: 'optout' } },
	subTitle: 'Last Updated: January 1, 2020',
	title: 'Right to Opt Out - California Residents',
}

const OPTOUT_INTRO_TEXT = `You have the right to opt out of the sale of your personal information by
business.com, buyerzone.com and businessnewsdaily.com. By completing the form below,
you are directing us not to sell your personal information. Please note that we will
send a confirmation email to the email address provided to acknowledge receipt of
your request. If we cannot deliver the email (e.g. the email is invalid), we will
not be able to fulfill your request as we cannot verify the information submitted.
Please contact us at 888-393-5000 if you do not receive a confirmation email or have
questions. You can also call us at 888-393-5000 to opt out.`

const ConnectWrapper = styled.div`
	height: 100%;
	width: 100%;
`
const ContentWrapper = styled.div`
	${({ theme }) => theme.maxWidth.L};
	margin: 0 auto;
	padding: 1rem 0 3rem;
`

function OptOutPage() {
	return (
		<ConnectWrapper>
			<Layout inverseLinkColors={true} meta={META}>
				<SimpleHeader canonical={META.canonical} {...HEADER_PROPS} />
				<ContentWrapper>
					<CcpaForm formIntroText={OPTOUT_INTRO_TEXT} />
				</ContentWrapper>
			</Layout>
		</ConnectWrapper>
	)
}

export default OptOutPage
